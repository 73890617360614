import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { doc, updateDoc } from 'firebase/firestore';
import db from '../../firebase/firebase';

const EditRestaurantForm = ({ restaurant, onClose, onUpdate }) => {
    const [formData, setFormData] = useState(restaurant);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const sendMerchantUpdateNotification = async (merchantId) => {
        const apiToken = process.env.REACT_APP_NOTIFICATION_API_TOKEN;
        const url = `${process.env.REACT_APP_NOTIFICATION_ENDPOINT}/${merchantId}/notifications`;

        const headers = {
            "Authorization": `Bearer ${apiToken}`,
            "Accept": "application/json",
            "Content-Type": "application/json"
        };
    
        const body = JSON.stringify({
            "event" : "merchant_update_notification",
            "data" : "Merchant Data Updated"
        });
    
        try {
            const response = await fetch(url, {
                method: "POST",
                headers,
                body
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            const result = await response.json();
            console.log("Notification Sent:", result);
            return result;
        } catch (error) {
            console.error("Error sending notification:", error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
    
        try {
            const restaurantRef = doc(db, "MEVMandatarys", restaurant.id);

            await updateDoc(restaurantRef, formData);
            onUpdate({ ...formData, id: restaurant.id });
            onClose();
            const merchantId = restaurant.cloverMerchantId;
            await sendMerchantUpdateNotification(merchantId);
            
        } catch (err) {
            console.error("Error details:", err);
            setError(`Failed to update restaurant and notify merchant. ${err.message}`);
        } finally {
            setLoading(false);
        }
    };

    const { t } = useTranslation();

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-4 rounded max-w-4xl h-[80vh] overflow-y-auto">
                <h2 className="text-xl text-sky-900 mb-4"><b>{t('edit_restaurant')} {restaurant.businessName}</b></h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4 flex justify-between w-full">
                        <div className="w-[33%]">
                            <label htmlFor="activitySubSector" className="block text-gray-700">{t('activity_sub_sector')}</label>
                            <select
                                id="activitySubSector"
                                name="activitySubSector"
                                value={formData.activitySubSector}
                                onChange={handleChange}
                                required
                                className="mt-1 p-2 w-full border rounded-lg"
                            >
                                <option value="" disabled>{t('option_select')}</option>
                                <option value="RES">Restaurant</option>
                                <option value="CDR">{t('food_truck')}</option>
                            </select>
                        </div>
                        <div className="w-[33%]">
                            <label htmlFor="authorizationCode" className="block text-gray-700">
                                {t('authorization_code')}
                            </label>
                            <input
                                type="text"
                                id="authorizationCode"
                                name="authorizationCode"
                                value={formData.authorizationCode}
                                onChange={handleChange}
                                required
                                className="mt-1 p-2 w-full border rounded-lg"
                                maxLength={9} // 9 characters including the dash
                                placeholder="C8T8-W8W8"
                                pattern="[A-Za-z0-9]{4}-[A-Za-z0-9]{4}" // Optional: enforce the pattern in the UI
                            />
                        </div>
                        <div className="w-[33%]">
                            <label htmlFor="billingFileNo" className="block text-gray-700">{t('billing_file_number')}</label>
                            <input
                                type="text"
                                id="billingFileNo"
                                name="billingFileNo"
                                value={formData.billingFileNo}
                                onChange={handleChange}
                                required
                                className="mt-1 p-2 w-full border rounded-lg"
                            />
                        </div>
                    </div>
                    <div className="mb-4 flex justify-between w-full">
                        <div className="w-[48%]">
                            <label htmlFor="businessName" className="block text-gray-700">{t('business_name')}</label>
                            <input
                                type="text"
                                id="businessName"
                                name="businessName"
                                value={formData.businessName}
                                onChange={handleChange}
                                required
                                className="mt-1 p-2 w-full border rounded-lg"
                            />
                        </div>
                        <div className="w-[48%]">
                            <label htmlFor="cloverMerchantId" className="block text-gray-700">{t('clover_merchant_id')}</label>
                            <input
                                type="text"
                                id="cloverMerchantId"
                                name="cloverMerchantId"
                                value={formData.cloverMerchantId}
                                onChange={handleChange}
                                required
                                className="mt-1 p-2 w-full border rounded-lg"
                            />
                        </div>
                    </div>
                    <div className="mb-4 flex justify-between w-full">
                        <div className="w-[48%]">
                            <label htmlFor="identificationNo" className="block text-gray-700">{t('identification_number')}</label>
                            <input
                                type="text"
                                id="identificationNo"
                                name="identificationNo"
                                value={formData.identificationNo}
                                onChange={handleChange}
                                required
                                placeholder='1234567890'
                                className="mt-1 p-2 w-full border rounded-lg"
                            />
                        </div>
                        <div className="w-[48%]">
                            <label htmlFor="phone" className="block text-gray-700">
                                {t('phone_number')}
                            </label>
                            <input
                                type="text"
                                id="phone"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                required
                                className="mt-1 p-2 w-full border rounded-lg"
                                placeholder="+1 (XXX) XXX-XXXX"
                                maxLength={17}
                            />
                        </div>
                    </div>
                    <div className="mb-4 flex justify-between w-full">
                        <div className="w-[33%]">
                            <label htmlFor="street1" className="block text-gray-700">{t('street_no')}</label>
                            <input
                                type="text"
                                id="street1"
                                name="street1"
                                value={formData.street1}
                                onChange={handleChange}
                                required
                                className="mt-1 p-2 w-full border rounded-lg"
                            />
                        </div>
                        <div className="w-[33%]">
                            <label htmlFor="street2" className="block text-gray-700">{t('street_name')}</label>
                            <input
                                type="text"
                                id="street2"
                                name="street2"
                                value={formData.street2}
                                onChange={handleChange}
                                required
                                className="mt-1 p-2 w-full border rounded-lg"
                            />
                        </div>
                        <div className="w-[33%]">
                            <label htmlFor="city" className="block text-gray-700">{t('city')}</label>
                            <input
                                type="text"
                                id="city"
                                name="city"
                                value={formData.city}
                                onChange={handleChange}
                                required
                                className="mt-1 p-2 w-full border rounded-lg"
                            />
                        </div>
                    </div>
                    <div className="mb-4 flex justify-between w-full">
                        <div className="w-[48%]">
                            <label htmlFor="province" className="block text-gray-700">{t('province')}</label>
                            <input
                                type="text"
                                id="province"
                                name="province"
                                value={formData.province}
                                onChange={handleChange}
                                required
                                className="mt-1 p-2 w-full border rounded-lg"
                            />
                        </div>
                        <div className="w-[48%]">
                            <label htmlFor="postalCode" className="block text-gray-700">{t('postal_code')}</label>
                            <input
                                type="text"
                                id="postalCode"
                                name="postalCode"
                                value={formData.postalCode}
                                onChange={handleChange}
                                required
                                className="mt-1 p-2 w-full border rounded-lg"
                            />
                        </div>
                    </div>
                    <div className="mb-4 flex justify-between w-full">
                        <div className="w-[48%]">
                            <label htmlFor="tpsNo" className="block text-gray-700">{t('tps_number')}</label>
                            <input
                                type="text"
                                id="tpsNo"
                                name="tpsNo"
                                value={formData.tpsNo}
                                onChange={handleChange}
                                placeholder='000000000RT0000'
                                required
                                className="mt-1 p-2 w-full border rounded-lg"
                            />
                        </div>
                        <div className="w-[48%]">
                            <label htmlFor="tvqNo" className="block text-gray-700">{t('tvq_number')}</label>
                            <input
                                type="text"
                                id="tvqNo"
                                name="tvqNo"
                                value={formData.tvqNo}
                                onChange={handleChange}
                                placeholder='0000000000TQ0000'
                                required
                                className="mt-1 p-2 w-full border rounded-lg"
                            />
                        </div>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="subNumber" className="block text-gray-700">{t('subNumber')}</label>
                        <input
                            type="text"
                            id="stasubNumbertus"
                            name="subNumber"
                            value={formData.subNumber}
                            onChange={handleChange}
                            className="mt-1 p-2 border rounded-lg"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="status" className="block text-gray-700">{t('status')}</label>
                        <input
                            type="checkbox"
                            id="status"
                            name="status"
                            checked={true}
                            onChange={handleChange}
                            className="mt-1 p-2 border rounded-lg"
                        />
                    </div>
                    {error && <p className="text-red-500 mt-1">{error}</p>}
                    <div className="flex justify-end">
                        <button
                            type="button"
                            className="mr-4 px-4 py-2 bg-gray-300 rounded"
                            onClick={onClose}
                            disabled={loading}
                        >
                            {t('cancel')}
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 bg-sky-900 text-white rounded"
                            disabled={loading}
                        >
                            {loading ? t('saving') : t('save')}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditRestaurantForm;
